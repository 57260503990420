
.gallery {
  display: flex;
  justify-content: center;
}

.photoContent {
  display: none;
  max-height: 300px;

  &.selected {
    display: inline-block;
    height: 300px;
    position: relative;
  }

  .galleryImg {
    width: 100%;
    height: 100%;
    object-fit: contain;
    border-radius: 5px;
  }

  &:hover {
    .caption {
      visibility: visible;
  }
}

  .caption {
    bottom: 0px;
    background-color: #00000096;
    color: white;
    left: 0;
    right: 0;
    position: absolute;
    visibility: hidden;
    border-radius: 5px;
    padding: 0.2em 0.4em 0.4em 0.4em;
  }
}