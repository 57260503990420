@import "../../dimensions.scss";

$accent-color: #ffb860;
$background-color-nav-bar: #5a035c;

.navBarLink {
  margin: 6px;
  color: white;
  text-decoration: none;

  &.selected {
    border-bottom: 2px solid $accent-color;
    font-weight: bolder;
    color: $accent-color;
  }
}

.navBarWrapper {
  display:flex;
  flex-direction: row;
  background-color: $background-color-nav-bar;
  height: $banner-height;
  align-items: flex-end;
  font-size: 1.2rem;
  padding-left: 12px;
  filter: drop-shadow(2px 0 3px black);
}

.rightSideContent {
  flex-grow: 2;
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: flex-end;
  height: 100%;

  .sourceLink {
    color: white;
    font-size: 0.9rem;
    font-weight: lighter;
    margin: 15px;
  }
}