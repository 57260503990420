.linkWithIcon {
  img {
    width: 36px;
    height: 36px;
  }
  display: flex;
  flex-direction: column;
  align-items: center;
  width: fit-content;
  margin: 5px;
}

.contactLinks {
  margin-top: 10px;
  display: flex;
  flex-direction: row;
  justify-content: center;
}